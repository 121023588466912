import { useAction } from '@hozana/hooks/useAction'
import { useDispatch } from '@hozana/redux/hooks'
import { getFirstQueryValue } from '@hozana/router/functions'
import { EVENTS, TRACKING_SOURCE, UTM } from '@hozana/tracking/constants'
import { GTM } from '@hozana/tracking/gtm'

import { addFlash, openPopin } from 'general/actions'
import { QUERY_ACTION } from 'general/constants'
import { POPINS } from 'general/managers/popins/constants'

import { SHARE_OBJECTS } from 'modules/share/constants'
import { deletePresubscriber, ignoreExhortation } from 'modules/user/routines'

/*
 * Handle actions which can be triggered on the homepage, or the user feed
 */
export const useCommonActions = () => {
  const dispatch = useDispatch()

  useAction(
    // from email link
    ({ email }) => {
      dispatch(ignoreExhortation({ email: getFirstQueryValue(email) }))
    },
    [dispatch],
    QUERY_ACTION.IGNORE_EXHORTATION,
    { queryKeysToRemove: ['email'] },
  )

  useAction(
    // from email link
    ({ email }) => dispatch(deletePresubscriber({ email: getFirstQueryValue(email) })),
    [dispatch],
    QUERY_ACTION.UNSUBSCRIBE_PRESUBSCRIBER,
    { queryKeysToRemove: ['email'] },
  )

  useAction(
    () =>
      dispatch(
        openPopin(POPINS.InvitePopin, {
          objectToShare: SHARE_OBJECTS.HOZANA,
          utmContent: UTM.CONTENT.HOZANA_URL_INVITE_POPIN,
          trackingSource: TRACKING_SOURCE.EMAIL_LINK,
        }),
      ),
    [dispatch],
    QUERY_ACTION.INVITE,
  )

  useAction(
    ({ message }) => dispatch(addFlash({ message: getFirstQueryValue(message) })),
    [dispatch],
    QUERY_ACTION.SHOW_FLASH,
    { queryKeysToRemove: ['message'] },
  )

  useAction(({ event }) => GTM.trackEvent(event as EVENTS), [], QUERY_ACTION.TRACK, {
    queryKeysToRemove: ['event'],
  })
}
