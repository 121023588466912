import React, { useEffect } from 'react'

import { useDispatch, useSelector } from '@hozana/redux/hooks'
import { useRouter } from '@hozana/router'

import { Overlay } from 'elements/layout/Overlay'

import { setTargetPathname } from 'general/actions'
import { selectTargetPathname } from 'general/selectors'

export const TransitionOverlay: React.FC = () => {
  const { pathname } = useRouter()
  const dispatch = useDispatch()
  const targetPathname = useSelector(selectTargetPathname)

  useEffect(() => {
    dispatch(setTargetPathname(pathname))
  }, [dispatch, pathname])

  return targetPathname && targetPathname !== pathname ? (
    <Overlay noPointerEvents bg="fadedWhite50" visible="xs" zIndex="subHeaderOverlay" />
  ) : null
}
